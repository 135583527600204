<template>
    <div class="MonitorDetailBaseBox">
        <detail-title class="header" :titleData="titleData" v-if="!isLoading" :routerParams="routerParams"></detail-title>
        <div class="btnBox" v-if="!isLoading">
            <div v-for="(item,index) in btnArr" :key="index" class="filfterBtn" :class="[check == index? 'active':'']" @click="filfterList(index,item.type)">{{item.name}}</div>
        </div>
        <div class="container">
            <loading v-if="isLoading" size="30px" style="height: 100%;"></loading>
            <scroll v-if="!isLoading&&detailList.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <ul ref="wrapper" style="padding: 0 15px;">
                    <li v-for="(item,index) in detailList" :key="index" class="docItem">
                        <van-row>
                            <van-col span="5">
                                <div class="smallSize">{{item.startTime.slice(11,16)}}</div>
                            </van-col>
                            <van-col span="5">
                                <div class="smallSize">邮件数</div>
                                <div class="bold">{{item.totalCount}}</div>
                            </van-col>
                            <van-col span="4">
                                <div class="smallSize">新/已收</div>
                                <div class="bold">{{item.newMessageCount}}/{{item.lastOrder}}</div>
                            </van-col>
                            <van-col span="5">
                                <div class="smallSize">距上轮</div>
                                <div class="bold">{{computeTime(item.startTime,index)}}</div>
                            </van-col>
                            <van-col span="5">
                                <div class="smallSize">用时</div>
                                <div class="bold">{{taskTime(item.startTime,item.finishTime)}}</div>
                            </van-col>
                            <van-col span="8">
                                <div class="smallSize">{{item.ip}}</div>
                            </van-col>
                            <van-col span="10">
                                <div>{{returnReason(item.reason)}}</div>
                            </van-col>
                            <van-col span="6">
                                <van-button round type="danger" size="small" plain @click="toLogUrl(item.lastRawLog)" v-show="item.hasOwnProperty('lastRawLog')">日志详情</van-button>
                            </van-col>
                        </van-row>
                    </li>
                </ul>
            </scroll>
        </div>
        <nodata title="暂无数据" img="noCustomer" v-if="!isLoading&&detailList.length==0" style="height: 100%;"></nodata>
    </div>
</template>

<script>
import detailTitle from './detailTitle'
import BScroll from 'better-scroll'
import Scroll from '@/components/Scroll/index'
import titleMixin from '@/mixin/title'
export default {
    name: 'AllTask',
    title: '所有任务',
    mixins: [titleMixin],
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            routerParams: {},
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            },
            isLoading: false,
            detailList: [],
            titleData: {},
            scroll: null,
            startY: 0,
            btnArr: [
                {
                    name: '不限'
                },
                {
                    name: '异常邮件',
                    type: 'abnormal'
                },
                {
                    name: '连接超时',
                    type: 'timeOutError'
                },
                {
                    name: '邮局踢出',
                    type: 'dropOutError'
                },
                {
                    name: '密码错误',
                    type: 'authError'
                }
            ],
            check: '0'
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    mounted() {
        this.setMenu()
        this.reGetList()
    },
    methods: {
        returnReason(str) {
            if (str == 900) {
                return '认证失败'
            } else if (str == 700) {
                return '邮局踢出'
            } else if (str == 600) {
                return '连接超时'
            } else if (str == 800) {
                return '异常邮件'
            }
        },
        taskTime(start, finish) {
            // 当前的完成时间减去开始时间就是用时
            var finishTime = finish.replace(/-/g, '/')
            var startTime = start.replace(/-/g, '/')
            var finishstamp = new Date(finishTime).getTime()
            var startstamp = new Date(startTime).getTime()
            let value = finishstamp - startstamp
            let valueMinutes = Math.ceil(value / 60000)
            if (valueMinutes < 1) {
                return valueMinutes * 60 + '秒'
            } else {
                return valueMinutes + '分钟'
            }
        },
        computeTime(currentTime, index) {
            if (this.detailList.length == index + 1) {
                return 0
            } else {
                var currentDate = new Date(currentTime.replace(/-/g, '/'))
                var lastTime = this.detailList[index + 1].startTime.replace(/-/g, '/')
                var lastDate = new Date(lastTime)
                let dateDiff = currentDate.getTime() - lastDate.getTime()
                return Math.ceil(dateDiff / 60000) + '分钟'
            }
        },
        async filfterList(index, type) {
            this.check = index
            this.page.pageN = 1
            this.isLoading = true
            this.detailList = await this.getDetailList(type)
            this.isLoading = false
        },
        setScroll() {
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.detailList = await this.getDetailList()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        async onPullingUp() {
            if (this.detailList.length >= this.page.total) {
                this.$nextTick(() => {
                    this.$refs.scroll.forceUpdate(false)
                })
                return
            }
            if (this.detailList.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getDetailList()
                this.detailList = this.detailList.concat(list)
                this.$nextTick(() => {
                    if (this.detailList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        toLogUrl(url) {
            window.open(url)
        },
        getTaskStatus() {
            this.routerParams = this.$route.query
            let params = {
                corpId: this.routerParams.corpId,
                mailAccount: this.routerParams.mailAccount
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_taskStatus, { params: params }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.titleData = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        async getDetailList(type) {
            console.log(type)
            this.routerParams = this.$route.query
            let listArr = []
            if (JSON.stringify(this.routerParams) != '{}') {
                try {
                    let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abNormalList
                    let params = {
                        corpId: this.routerParams.corpId,
                        mailAccount: this.routerParams.mailAccount,
                        pageN: this.page.pageN,
                        pageSize: this.page.pageSize,
                        days: this.routerParams.days
                    }
                    if (type != undefined) {
                        params.type = type
                    }
                    let res = await this.axios.get(url, { params })
                    if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                        listArr = res.data.data.dataList
                        this.page.total = res.data.data.totalNum
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                } catch (error) {
                    this.isLoading = false
                    console.log(error)
                }
            }
            return listArr
        },
        async reGetList(x) {
            this.page.pageN = 1
            this.isLoading = true
            this.detailList = await this.getDetailList(x)
            await this.getTaskStatus()
            this.isLoading = false
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/mailmonitor/alltask') {
                this.reGetList()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'detail-title': detailTitle,
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
