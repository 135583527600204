<template>
    <div class="detailTitle">
        <div class="detailHeader">
            <div class="left">
                <div class="bold">{{titleData.mailAddress}}</div>
                <div>{{titleData.companyName}}</div>
            </div>
            <div class="right">
                <span style="margin-right: 5px;">{{titleData.initFlag == '1'?'初始化完成':'初始化未完成'}}</span>
                <span>{{titleData.serverType == 0?'POP':titleData.serverType == 1 ? 'IMAP' : ''}}</span></div>
            <div class="right" style="top: 20px;" @click="recentlyAccept">最近接收</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'detailTitle',
    data() {
        return {
        }
    },
    props: {
        titleData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        routerParams: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    computed: {
        received() {
            // if (this.titleData.hasOwnProperty('currentOrder')) {
            //     let result = this.titleData.currentOrder
            //     if () {
            //     }
            // } else {
            //     return 0
            // }
        }
    },
    created() {
    },
    mounted() { },
    methods: {
        recentlyAccept() {
            this.$router.push(
                {
                    path: '/mailmonitor/recentlyaccept',
                    query: {
                        id: this.routerParams.id
                    }
                }
            )
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.detailTitle {
    background: #fff;
    padding: 10px;
}
.detailHeader {
    position: relative;
    .left {
        width: 70%;
        font-size: 12px;
        .bold {
            font-weight: bold;
            font-size: 15px;
        }
    }
    .right {
        position: absolute;
        right: 5px;
        top: 0;
        font-weight: bold;
        color: #333;
    }
}
.titleBtn {
    position: absolute;
    right: 0px;
    top: 0;
}
.emailNum {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    .bold {
        font-weight: bold;
    }
}
</style>
